<div class="row">
  <div class="col-12">
    <div class="row align-items-center form-group">
      <div class="col-2">
        <label for="bundle">{{ 'serviceData.form.bundle' | translate }}</label>
      </div>
      <div class="col-9">
        <div class="d-flex">
          @if (bundles$ | async; as bundles) {
            <nb-select
              id="bundle"
              fullWidth
              [formControl]="bundleFormControl"
              [placeholder]="
                bundles.length
                  ? ('serviceData.form.placeholders.selectBundle' | translate)
                  : ('serviceData.form.placeholders.noBundlesAvailable'
                    | translate)
              "
              data-cy="bundle-select"
            >
              @for (bundle of bundles; track bundle) {
                <nb-option [value]="bundle?.id">
                  {{ bundle?.name }}
                </nb-option>
              }
            </nb-select>
          } @else {
            <nb-select
              fullWidth
              placeholder="{{
                'serviceData.form.placeholders.selectPartnerFirst' | translate
              }}"
              [formControl]="bundleFormControl"
              data-cy="disabled-bundle-select"
            />
          }
        </div>
      </div>
      <div class="col-1">
        <button
          nbButton
          nbTooltip="{{
            'serviceData.form.tooltips.assignBundleToServiceData' | translate
          }}"
          nbTooltipPlacement="left"
          status="primary"
          [disabled]="
            !bundleFormControl?.value || form.get('bundleIds').value?.length
          "
          (click)="assignBundle()"
          data-cy="assign-bundle-btn"
        >
          <nb-icon icon="plus-outline" />
        </button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-10">
        <app-data-table [configuration]="dataTableConfiguration" />
      </div>
    </div>
    <div class="row justify-content-end form-group">
      <div class="col-10">
        <app-validation-error
          for="bundleIds"
          translations="serviceData.form.validation.bundles"
        ></app-validation-error>
      </div>
    </div>
  </div>

  <ng-container formGroupName="serviceInformation">
    <ng-container formGroupName="nipt">
      <ng-container formGroupName="patientHistory">
        @if (selectedDataInputTypes.includes(DataInputType.RH)) {
          <div class="row form-group" formGroupName="rhdStatus">
            <div class="col-6">
              <div class="row align-items-center">
                <div class="col-4">
                  <label for="patientRhdNegative">{{
                    'serviceData.form.patientRhdNegative' | translate
                  }}</label>
                </div>
                <div class="col-8">
                  <div class="col-9">
                    <nb-radio-group
                      id="patientRhdNegative"
                      name="patientRhdNegative"
                      class="radio-toolbar"
                      formControlName="status"
                      data-cy="gender-information-rg"
                    >
                      <nb-radio [value]="true">{{
                        'common.yes' | translate
                      }}</nb-radio>
                      <nb-radio [value]="false">{{
                        'common.no' | translate
                      }}</nb-radio>
                    </nb-radio-group>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-8">
                  <app-validation-error
                    for="status"
                    translations="serviceData.form.validation.rhdStatus"
                  ></app-validation-error>
                </div>
              </div>
            </div>
          </div>
        }
        @if (selectedDataInputTypes.includes(DataInputType.CARRIER)) {
          <ng-container formGroupName="cysticFibrosisHistory">
            <div class="row form-group">
              <div class="col-6">
                <div class="row align-items-center">
                  <div class="col-4">
                    <label for="cysticFibrosisHistory">{{
                      'serviceData.form.cysticFibrosisHistory' | translate
                    }}</label>
                  </div>
                  <div class="col-8">
                    <div class="col-9">
                      <nb-radio-group
                        id="cysticFibrosisHistory"
                        name="cysticFibrosisHistory"
                        class="radio-toolbar"
                        formControlName="hasHistory"
                        data-cy="gender-information-rg"
                      >
                        <nb-radio [value]="true">{{
                          'common.yes' | translate
                        }}</nb-radio>
                        <nb-radio [value]="false">{{
                          'common.no' | translate
                        }}</nb-radio>
                      </nb-radio-group>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-8">
                    <app-validation-error
                      for="hasHistory"
                      translations="validation.generic"
                    ></app-validation-error>
                  </div>
                </div>
              </div>
            </div>
            @if (form.get('serviceInformation.nipt.patientHistory.cysticFibrosisHistory.hasHistory').value) {
              <div class="row">
                <div class="col-2">
                  <label for="cysticFibrosisHistoryConditionDescription">{{
                    'serviceData.form.cysticFibrosisHistory' | translate
                  }}</label>
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-6">
                      <div class="row align-items-center form-group">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              fullWidth
                              nbInput
                              id="cysticFibrosisHistoryConditionDescription"
                              [formControl]="
                                cysticFibrosisHistoryGroup.get(
                                  'conditionDescription'
                                )
                              "
                              [placeholder]="
                                'serviceData.form.placeholders.diseaseInformation'
                                  | translate
                              "
                              data-cy="history-of-cystic-fibrosis-description-input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 gr-0">
                      <div class="row align-items-center justify-content-end">
                        <div class="col-12">
                          <div class="d-flex">
                            <input
                              fullWidth
                              nbInput
                              id="cysticFibrosisHistoryRelationToPatient"
                              [formControl]="
                                cysticFibrosisHistoryGroup.get(
                                  'relationToPatient'
                                )
                              "
                              [placeholder]="
                                'serviceData.form.placeholders.relationToPatient'
                                  | translate
                              "
                              data-cy="history-of-cystic-fibrosis-relation-input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <button
                    nbButton
                    nbTooltip="{{
                      'serviceData.form.tooltips.addFamilyDisease' | translate
                    }}"
                    nbTooltipPlacement="left"
                    status="primary"
                    [disabled]="
                      !cysticFibrosisHistoryGroup.get('conditionDescription')
                        .value ||
                      !cysticFibrosisHistoryGroup.get('relationToPatient')
                        .value
                    "
                    (click)="addcysticFibrosisHistory()"
                    data-cy="add-family-disease-btn"
                  >
                    <nb-icon icon="plus-outline" />
                  </button>
                </div>
              </div>
              <div class="row justify-content-end form-group">
                <div class="col-10">
                  <app-data-table
                    [configuration]="cysticFibrosisHistoryConfiguration"
                  />
                </div>
              </div>
            }
          </ng-container>
        }
      </ng-container>
      <div class="row form-group">
        <div class="col-6">
          <div class="row align-items-center">
            <div class="col-4">
              <label for="provideGenderInformation">{{
                'serviceData.form.provideGenderInformation' | translate
              }}</label>
            </div>
            <div class="col-8">
              <div class="col-9">
                <nb-radio-group
                  id="provideGenderInformation"
                  name="provideGenderInformation"
                  class="radio-toolbar"
                  formControlName="provideGenderInformation"
                  data-cy="gender-information-rg"
                >
                  <nb-radio [value]="true">{{
                    'common.yes' | translate
                  }}</nb-radio>
                  <nb-radio [value]="false">{{
                    'common.no' | translate
                  }}</nb-radio>
                </nb-radio-group>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-8">
              <app-validation-error
                for="provideGenderInformation"
                translations="validation.generic"
              ></app-validation-error>
            </div>
          </div>
        </div>
      </div>

      @if (form.get('serviceInformation.nipt.incidentalFindings').enabled) {
        <div class="row form-group">
          <div class="col-6">
            <div class="row align-items-center">
              <div class="col-4">
                <label for="incidentalFindings">{{
                  'serviceData.form.incidentalFindings' | translate
                }}</label>
              </div>
              <div class="col-8">
                <div class="col-9">
                  <nb-radio-group
                    id="incidentalFindings"
                    name="incidentalFindings"
                    class="radio-toolbar"
                    formControlName="incidentalFindings"
                    data-cy="incidental-findings-rg"
                  >
                    <nb-radio [value]="true">{{
                      'common.yes' | translate
                    }}</nb-radio>
                    <nb-radio [value]="false">{{
                      'common.no' | translate
                    }}</nb-radio>
                  </nb-radio-group>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-8">
                <app-validation-error
                  for="incidentalFindings"
                  translations="validation.generic"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
      }
    </ng-container>
  </ng-container>
</div>
